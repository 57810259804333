import { Form, Field } from 'formik'
import PropTypes from 'prop-types'
import { Input, Button, FormGroup } from 'reactstrap'
import 'assets/styles/Login.css'
// TODO: Update inputs
const ErrorMessage = (errors) => {
	let messages = []
	messages = Object.values(errors.errors).map((error) => {
		switch (error) {
			case 'Request failed with status code 401':
				return 'Provided email password combination is incorrect. Please try again'
			default:
				return error
		}
	})
	return (
		<div style={{ marginLeft: 20, marginTop: 10 }}>
			<div className='text-danger'>{messages}</div>
		</div>
	)
}
export const FormikInput = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<>
		<Input
			className='form-control'
			{...field}
			{...props}
			style={{
				borderColor: errors[field.name] && touched[field.name] && 'red',
			}}
		/>
		{errors[field.name] && touched[field.name] ? (
			<div style={{ color: 'red' }}>{errors[field.name]}</div>
		) : null}
	</>
)

FormikInput.propTypes = {
	form: PropTypes.object,
	field: PropTypes.object,
}

const InnerForm = ({ isSubmitting, responseErrors }) => (
	<Form>
		<FormGroup controlId='email'>
			<Field
				type='email'
				name='email'
				id='email'
				placeholder='Enter your email'
				component={FormikInput}
			/>
		</FormGroup>
		<FormGroup controlId='password'>
			<Field
				name='password'
				type='password'
				id='password'
				placeholder='Enter your password'
				component={FormikInput}
			/>
		</FormGroup>
		<Button
			block
			size='lg'
			outline
			color='primary'
			type='submit'
			disabled={isSubmitting}>
			Log in
		</Button>
		<ErrorMessage errors={responseErrors} />
	</Form>
)
InnerForm.propTypes = {
	isSubmitting: PropTypes.bool,
	responseErrors: PropTypes.object,
}
export default InnerForm
