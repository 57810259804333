import { Formik } from 'formik'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import InnerForm from './InnerForm.js'

const initialValues = {
	title: undefined,
	body: '',
}

const validate = (values) => {
	let errors = {}
	const { title, body } = values
	if (!title) {
		errors.title = 'Please, write a subject'
	}
	if (!body) {
		errors.body = 'Please, create the email body'
	}
	return errors
}

const TemplateForm = ({
	isLoading,
	error,
	handleSave,
	order,
	template = null,
	history,
}) => {
	const initial = initialValues
	if (template) {
		initial.title = template.title
		initial.body = template.body
	}
	return (
		<Formik
			initialValues={initial}
			validate={validate}
			onSubmit={(values, { setSubmitting }) => {
				handleSave({ ...values, order: order }).then(() =>
					history.push('/payments/services/all'),
				)
				setSubmitting(false)
			}}
			component={(props) => (
				<InnerForm {...props} isLoading={isLoading} error={error} />
			)}
		/>
	)
}

export default withRouter(TemplateForm)

TemplateForm.propTypes = {
	isLoading: PropTypes.bool,
	error: PropTypes.string,
	order: PropTypes.number.isRequired,
	handleSave: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	template: PropTypes.object,
}
