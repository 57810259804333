import PropTypes from 'prop-types'
import { Field } from 'formik'
import { Form, Button } from 'reactstrap'
import ErrorMessage from 'app/_common/components/ErrorMessage'
import {
	FormikSelect,
	FormikSelectCompany,
	FormikSelectPerson,
	FormikSwitch,
	FormikSelectEmail,
	TransferButtons,
	DateInput,
	FormikSelectLead,
	FormikMoneyInput,
	FormikTextArea,
} from './Inputs'
import groupBy from 'lodash/groupBy'

const groupStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
}
const groupBadgeStyles = {
	backgroundColor: '#EBECF0',
	borderRadius: '2em',
	color: '#172B4D',
	display: 'inline-block',
	fontSize: 12,
	fontWeight: 'normal',
	lineHeight: '1',
	minWidth: 1,
	padding: '0.16666666666667em 0.5em',
	textAlign: 'center',
}
const formatGroupLabel = (data) => (
	<div style={groupStyles}>
		<span>{data.label}</span>
		<span style={groupBadgeStyles}>{data.options.length}</span>
	</div>
)

const checkIfNotPaying = (state) =>
	state &&
	(state.label === 'Not paying & suspended' || state.label === 'Not paying')

const InnerModal = ({
	handleSubmit,
	onClose,
	error,
	serviceStatusOptions,
	values,
	interestOptions,
	userOptions,
	showLeadField,
}) => {
	const { serviceType, serviceStatus } = values
	const groupedInterests = groupBy(interestOptions, (i) => i.general)
	const groupedInterestOptions = Object.keys(
		groupedInterests,
	).map((general) => ({ label: general, options: groupedInterests[general] }))

	return (
		<div style={{ maxWidth: '800px', margin: 'auto' }}>
			<Form>
				<Field
					component={FormikSelect}
					name='serviceStatus'
					label='Service status'
					options={serviceStatusOptions}
					dataCy='serviceStatus'
				/>
				<Field
					component={FormikSelectEmail}
					name='email'
					label='Contact email'
				/>

				<Field
					name='serviceType'
					component={FormikSelect}
					formatGroupLabel={formatGroupLabel}
					options={groupedInterestOptions}
					label='Service type'
				/>

				{serviceType ? (
					serviceType.entityType === 'person' ? (
						<Field
							component={FormikSelectPerson}
							name='person'
							label='Related person'
							dataCy='relatedPerson'
						/>
					) : (
						<Field
							component={FormikSelectCompany}
							name='company'
							label='Related company'
							dataCy='relatedCompany'
						/>
					)
				) : (
					[]
				)}
				<Field name='transfer' component={TransferButtons} />
				<Field
					name='converted_at'
					component={DateInput}
					label='Conversion date'
				/>

				<Field
					label='Converted by'
					name='converted_by'
					component={FormikSelect}
					options={userOptions}
					placeholder='Select user...'
				/>
				{showLeadField ? (
					<Field
						label='Lead'
						name='lead'
						component={FormikSelectLead}
					/>
				) : null}

				{checkIfNotPaying(serviceStatus) && (
					<>
						<hr />
						<Field
							component={FormikMoneyInput}
							label='Amount due'
							name='moneyDue'
						/>

						<Field
							component={FormikTextArea}
							name='notes'
							label='Additional notes'
						/>
						<Field
							component={FormikSwitch}
							name='sendEmails'
							label='Should contact be emailed'
						/>
					</>
				)}
			</Form>
			<ErrorMessage errors={{ errors: error }} />
			<hr />
			<Button color='primary' onClick={handleSubmit}>
				Submit
			</Button>
			<Button color='link' onClick={onClose}>
				Cancel
			</Button>
		</div>
	)
}

InnerModal.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	error: PropTypes.string,
	serviceStatusOptions: PropTypes.array.isRequired,
	serviceTypeOptions: PropTypes.array.isRequired,
	values: PropTypes.object.isRequired,
	interestOptions: PropTypes.array.isRequired,
	userOptions: PropTypes.array.isRequired,
	showLeadField: PropTypes.bool.isRequired,
}

export default InnerModal
