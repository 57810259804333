import PropTypes from 'prop-types'
import { Field } from 'formik'
import { Form, Button } from 'reactstrap'
import ErrorMessage from 'app/_common/components/ErrorMessage'
import { FormikInput, FormikTextEditor } from './Inputs'

const InnerForm = ({ handleSubmit, error }) => {
	return (
		<div style={{ maxWidth: '1000px', margin: 'auto' }}>
			<Form>
				<Field
					component={FormikInput}
					name='title'
					label='Title'
					dataCy='title'
				/>
				<Field
					component={FormikTextEditor}
					name='body'
					label='Body'
					dataCy='body'
				/>
			</Form>
			<ErrorMessage errors={{ errors: error }} />
			<hr />
			<Button color='primary' onClick={handleSubmit}>
				Submit
			</Button>
		</div>
	)
}

InnerForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	error: PropTypes.string,
}

export default InnerForm
