import PropTypes from 'prop-types'
import trashIconBlack from 'assets/trash-black.svg'
import { Button, ButtonGroup } from 'reactstrap'
import InputFilter from 'app/_common/components/InputFilter'
import InfoPopover from 'app/_common/components/InfoPopover'

const MAIL_INFO =
	'When set to "Yes" an email will be sent every week to the client starting from 9 a.m. next day. A script that queues them works at 3 a.m. every day, so you won\'t be able to stop the email after that time.'

const SendEmailFilter = ({ filter = null, handleFilteredChange, name }) => {
	const options = ['yes', 'no']
	const handleClick = (button) => {
		const newFilter = filter === button ? null : button
		handleFilteredChange(newFilter, name)
	}
	return (
		<ButtonGroup size='sm'>
			{options.map((option, index) => (
				<Button
					key={index}
					outline={!(filter === option)}
					color='primary'
					onClick={() => handleClick(option)}>
					{option.toUpperCase()}
				</Button>
			))}
		</ButtonGroup>
	)
}
SendEmailFilter.propTypes = {
	filter: PropTypes.string,
	handleFilteredChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
}

const DeleteButton = ({ row, handleClickDelete }) => (
	<Button
		color='danger'
		name={'delete'}
		style={{ float: 'right' }}
		onClick={() => handleClickDelete(row.original.id)}>
		X
	</Button>
)
DeleteButton.propTypes = {
	handleClickDelete: PropTypes.func.isRequired,
	row: PropTypes.object.isRequired,
}

const HeaderWithInfo = ({ label }) => (
	<>
		{label}
		<InfoPopover title='Sending emails' content={MAIL_INFO} />
	</>
)
HeaderWithInfo.propTypes = { label: PropTypes.string.isRequired }

const getColumns = (handleClickDelete, onFilteredChange, filters) => [
	{
		Header: 'ID:',
		accessor: 'id',
		Filter: () => (
			<InputFilter
				handleFilteredChange={onFilteredChange}
				name='id'
				filters={filters}
				min='1'
			/>
		),
		width: '7%',
	},
	{
		Header: 'Client name:',
		accessor: 'name',
		Filter: () => (
			<InputFilter
				handleFilteredChange={onFilteredChange}
				name='client_name'
				filters={filters}
			/>
		),
		width: '20%',
	},

	{
		Header: 'Status:',
		accessor: 'service_status.name',
		Filter: () => (
			<InputFilter
				handleFilteredChange={onFilteredChange}
				name='service_status'
				filters={filters}
			/>
		),
		width: '20%',
	},
	{
		Header: 'Type:',
		accessor: 'service_type.name',
		Filter: () => (
			<InputFilter
				handleFilteredChange={onFilteredChange}
				name='service_type'
				filters={filters}
			/>
		),
		width: '20%',
	},
	{
		Header: <HeaderWithInfo label='Is being emailed:' />,
		accessor: 'is_emailed',
		Filter: () => (
			<SendEmailFilter
				handleFilteredChange={onFilteredChange}
				name='is_emailed'
				filter={filters['is_emailed']}
			/>
		),
		width: '14%',
	},
	{
		Header: 'Amount due:',
		accessor: 'money_due',
		disableFilters: true,
		width: '10%',
	},
	{
		Header: (
			<img
				src={trashIconBlack}
				style={{ width: '2em', float: 'right' }}
				alt='trashIconBlack'
			/>
		),
		accessor: 'deleteButtons',
		Cell: (props) => (
			<DeleteButton {...props} handleClickDelete={handleClickDelete} />
		),
		disableFilters: true,
		width: '10%',
	},
]

export default getColumns
