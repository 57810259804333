import axios from 'axios'

const configureAxios = () => {
	axios.defaults.baseURL = process.env.REACT_APP_API
	axios.defaults.timeout = 10000
	axios.interceptors.request.use((config) => {
		const text = window.localStorage.getItem('text')

		config.headers.common['Text'] = text
		return config
	})
}
export default configureAxios
