import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'
import orm from 'store/reducers/models/orm'
import { TEMPLATE, UPSERT_TEMPLATE } from './types'
import { createSelector } from 'redux-orm'

export const getTemplateById = createSelector(orm.MailTemplate)

export const getIsFetching = createLoadingSelector([TEMPLATE, UPSERT_TEMPLATE])

export const getError = createErrorSelector([TEMPLATE, UPSERT_TEMPLATE])
