import { useState } from 'react'
import PropTypes from 'prop-types'
import {
	FormGroup,
	Label,
	Input,
	FormFeedback,
	FormText,
	Card,
	CardBody,
} from 'reactstrap'

import { convertToRaw, EditorState, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'

const getHtml = (editorState) =>
	draftToHtml(convertToRaw(editorState.getCurrentContent()))

const convertToDraft = (body) => {
	const blocksFromHtml = htmlToDraft(body)
	const { contentBlocks, entityMap } = blocksFromHtml
	const contentState = ContentState.createFromBlockArray(
		contentBlocks,
		entityMap,
	)
	const editorState = EditorState.createWithContent(contentState)
	return editorState
}

const EditorComponent = ({ body = null, handleSubmit }) => {
	const initial = body ? convertToDraft(body) : Editor.createEmpty
	const [editorState, setEditorState] = useState(initial)

	return (
		<Editor
			editorState={editorState}
			wrapperClassName='rich-editor demo-wrapper'
			editorClassName='demo-editor'
			onEditorStateChange={setEditorState}
			placeholder='Email body'
			onBlur={() => handleSubmit(getHtml(editorState))}
		/>
	)
}

EditorComponent.propTypes = {
	body: PropTypes.string,
	handleSubmit: PropTypes.func.isRequired,
}

export const FormikTextEditor = ({
	field,
	form: { touched, errors, setFieldValue },
	...props
}) => {
	return (
		<FormGroup>
			<Label for={field.name}>{props.label}</Label>
			<Card>
				<CardBody>
					<EditorComponent
						body={field.value}
						handleSubmit={(body) => setFieldValue(field.name, body)}
					/>
				</CardBody>
			</Card>
			<FormText color='muted'>
				<div>
					{'Available variables: {amount_due}, {currency}, {notes}'}
				</div>
				<div>
					{
						'e.g. Labore consequat sit minim laboris {currency} culpa.'
					}
				</div>
			</FormText>
			<FormFeedback valid={!(errors[field.name] && touched[field.name])}>
				{errors[field.name]}
			</FormFeedback>
		</FormGroup>
	)
}

FormikTextEditor.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	placeholder: PropTypes.string,
	label: PropTypes.string.isRequired,
	dataCy: PropTypes.string.isRequired,
}

export const FormikInput = ({
	field,
	form: { touched, errors, setFieldValue, setFieldTouched },
	...props
}) => {
	return (
		<FormGroup>
			<Label for={field.name}>{props.label}</Label>
			<Input
				data-cy={props.dataCy}
				invalid={!!(errors[field.name] && touched[field.name])}
				{...field}
				{...props}
				id={field.name}
				placeholder={props.placeholder}
				onChange={({ target: { value } }) =>
					setFieldValue(field.name, value)
				}
				onBlur={() => setFieldTouched(field.name, true)}
			/>
			<FormFeedback valid={!(errors[field.name] && touched[field.name])}>
				{errors[field.name]}
			</FormFeedback>
		</FormGroup>
	)
}
FormikInput.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	placeholder: PropTypes.string,
	label: PropTypes.string.isRequired,
	dataCy: PropTypes.string.isRequired,
}
