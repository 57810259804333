import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'reactstrap'

const templates = [1, 2, 3]

const TemplateSelection = ({ history, location }) => {
	const activeTemplateId = Number(
		location.pathname.split('/').slice(-1).pop(),
	)
	return (
		<>
			<ButtonGroup>
				{templates.map((template, index) => {
					const isCurrent =
						Number(template) === Number(activeTemplateId)
					return (
						<Button
							key={'tempButton-' + index}
							color={isCurrent ? 'primary' : 'link'}
							onClick={() =>
								history.push(
									'/payments/mail-templates/' + template,
								)
							}
							active={isCurrent}>
							{'Template #' + template}
						</Button>
					)
				})}
			</ButtonGroup>
			<hr />
		</>
	)
}

export default withRouter(TemplateSelection)

TemplateSelection.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
}
