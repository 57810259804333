import { connect } from 'react-redux'
import { logIn } from '../../../reducers/actions'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import InnerForm from './InnerForm'
import * as regex from 'app/_common/utils/regex'
import 'assets/styles/Login.css'

const LogInForm = ({ logIn, errors }) => {
	return (
		<div className='Login'>
			<Formik
				validateOnChange={false}
				initialValues={{ email: '', password: '' }}
				validate={(values) => {
					const errors = {}
					if (!values.email) {
						errors.email = 'Please insert your email address'
					} else if (!regex.email.test(values.email)) {
						errors.email = 'Plese supply a valid email address'
					}
					return errors
				}}
				onSubmit={(values, { setSubmitting }) => {
					logIn({
						email: values.email,
						password: values.password,
					})
					setSubmitting(false)
				}}>
				{(props) => <InnerForm {...props} responseErrors={errors} />}
			</Formik>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		errors: state.UI.error,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		logIn: (data) => dispatch(logIn(data)),
	}
}

LogInForm.propTypes = {
	errors: PropTypes.object,
	logIn: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(LogInForm)
