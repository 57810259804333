const errorReducer = (state = {}, action) => {
	const { type, error } = action
	const matches = /(.*)\/(pending|rejected)/.exec(type)

	if (!matches) return state
	let message = ''
	const [, requestName, requestState] = matches
	if (requestState === 'rejected') {
		switch (error.message) {
			case 'timeout of 2000ms exceeded':
				message =
					"Server's response was too slow, try again in a while."
				break
			case 'Network Error': {
				message =
					'Something went wrong on the server. Try again in a while or contact administration.'
				break
			}
			case 'Request failed with status code 500': {
				message =
					'Something went wrong on the server. Try again in a while or contact administration.'
				break
			}
			case 'Request failed with status code 409': {
				message =
					'Lead name already exists in database. Please choose different one'
				break
			}
			case 'Request failed with status code 403': {
				message = 'You are not authorized to do this'
				break
			}

			default:
				message = error.message
		}
	}
	return {
		...state,
		[requestName]: message,
	}
}

export default errorReducer
