import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchTemplate, upsertTemplate } from '../reducers/actions'
import { getTemplateById, getError, getIsFetching } from '../reducers/selectors'
import { bindActionCreators } from '@reduxjs/toolkit'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import WithError from 'app/_common/containers/HOC/WithError'
import TemplateForm from '../components/TemplateForm'

const EnhancedTemplateForm = WithError(WithLoading(TemplateForm))

const TemplateEdition = ({
	isLoading,
	error,
	fetchTemplate,
	upsertTemplate,
	templateId,
	template,
}) => {
	useEffect(() => {
		fetchTemplate(templateId)
	}, [fetchTemplate, templateId])

	return (
		<EnhancedTemplateForm
			isLoading={isLoading}
			error={error}
			handleSave={upsertTemplate}
			template={template}
			order={parseInt(templateId)}
		/>
	)
}
TemplateEdition.propTypes = {
	isLoading: PropTypes.bool,
	error: PropTypes.string,
	upsertTemplate: PropTypes.func.isRequired,
	fetchTemplate: PropTypes.func.isRequired,
	templateId: PropTypes.string.isRequired,
	template: PropTypes.object.isRequired,
}
const mapStateToProps = (state, ownProps) => {
	const templateId = ownProps.match.params.id
	return {
		templateId: templateId,
		template: getTemplateById(state, templateId),
		isFetching: getIsFetching(state),
		error: getError(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ fetchTemplate, upsertTemplate }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(TemplateEdition)
